import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import { LANDING_GAMES } from '../constants/landing-games.constants';
import { ROUTES } from 'constants/routes.constant';

import { ScrollContext } from 'contexts/ScrollContext';

import Button from 'components/buttons/Button';
import ScrollInAnimationItem from 'components/scrollInAnimationItem/ScrollInAnimationItem';

import { useLandingScrollSectionState } from 'hooks/landing/useLandingScrollSectionState';

import LandingGameCard from '../landingGameCard/LandingGameCard';
import styles from './LandingGamesSection.module.scss';

const LandingGamesSection = () => {
  const navigate = useNavigate();

  const { sectionRefs } = useContext(ScrollContext);
  const defaultCardWidth = 330;

  useLandingScrollSectionState('games');

  return (
    <section className={styles['section']} ref={sectionRefs.games}>
      <div className={styles['container']}>
        <ScrollInAnimationItem className={styles['summary']} delay="0.1s" threshold={0.3}>
          <h2>
            GAMES POWERED BY <b>RMV</b> AND <b>DIGITAL ASSETS</b> ECONOMY
          </h2>
          <p className={`landing-main-text ${styles['main-text']}`}>
            Reality Metaverse is constantly expanding its ecosystem with new games where the RMV token serves as the
            core currency, and world real assets act as premium content, unlocking unique in-game experiences and
            benefits.
          </p>
        </ScrollInAnimationItem>
        <div>
          <ScrollInAnimationItem delay="0.2s" threshold={0.3}>
            <Slider
              dots
              arrows={false}
              infinite={false}
              variableWidth
              speed={300}
              autoplaySpeed={3000}
              centerPadding="0"
              dotsClass={`slick-dots slick-dots--rect ${styles['carousel-dots']}`}
              className={styles['slider']}
              slidesToShow={6}
              slidesToScroll={6}
              responsive={[
                {
                  breakpoint: defaultCardWidth * 6,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                  },
                },
                {
                  breakpoint: defaultCardWidth * 5,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                  },
                },
                {
                  breakpoint: defaultCardWidth * 4,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
                {
                  breakpoint: defaultCardWidth * 3,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: defaultCardWidth * 2,
                  settings: {
                    centerMode: true,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '0',
                  },
                },
              ]}
              useTransform={false}
            >
              {LANDING_GAMES.map((game) => (
                <LandingGameCard key={game.title} className={styles['card']} game={game} />
              ))}
            </Slider>
          </ScrollInAnimationItem>
        </div>
        <Button
          size="medium"
          color="primary"
          className="tw-mx-auto tw-mt-20 tw-w-[140px] lg:tw-mt-15"
          onClick={() => navigate(ROUTES.GAMES)}
        >
          Play now
        </Button>
      </div>
    </section>
  );
};

export default LandingGamesSection;
