import { useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useEffectOnce } from 'react-use';

import { IState } from 'models/reducers/i-state.interface';

import { LANDING_NFTS } from '../constants/landing-nfts.constants';
import { ROUTES } from 'constants/routes.constant';

import { ScrollContext } from 'contexts/ScrollContext';

import { fetchLandingAssetsAction } from 'actions/landing.action';

import ScrollInAnimationItem from 'components/scrollInAnimationItem/ScrollInAnimationItem';

import { useMarketEvents } from 'hooks/events/useMarketEvents';
import { useLandingScrollSectionState } from 'hooks/landing/useLandingScrollSectionState';

import SeeMoreArrow from 'assets/landing/icons/see-more-arrow.svg';

import LandingNftCard from '../landingNftCard/LandingNftCard';
import styles from './LandingNftsSection.module.scss';

const LandingNftsSection = () => {
  const dispatch = useDispatch();
  const assets = useSelector(({ landing }: IState) => landing.assets);

  useEffectOnce(() => {
    dispatch(fetchLandingAssetsAction(LANDING_NFTS));
  });

  const carouselRef = useRef<Slider>(null);
  const defaultCardWidth = 280;
  const { sectionRefs } = useContext(ScrollContext);
  useLandingScrollSectionState('realityNFT');

  const { sendMarketDisplayAuction } = useMarketEvents();

  return (
    <section className={['landing-page-container', styles['section']].join(' ')} ref={sectionRefs.realityNFT}>
      <div className={styles['container']}>
        <ScrollInAnimationItem className={styles['summary']} delay="0.1s" threshold={0.3}>
          <h2>
            <b>ASSETS</b> IN THE RMV ECONOMY
          </h2>
          <p className={`landing-main-text ${styles['main-text']}`}>
            Explore 2,000 stunning 3D landmarks inspired by real-world buildings, cities, and countries. These
            fractional digital assets seamlessly integrate into games and metaverses, enhancing your virtual collection
            and unlocking new opportunities.
          </p>
          <Link to={ROUTES.MARKET} className={styles['link']} onClick={sendMarketDisplayAuction}>
            <span>Open marketplace</span>
            <img src={SeeMoreArrow} alt="" />
          </Link>
        </ScrollInAnimationItem>
        <div className={styles['carousel-container']}>
          <ScrollInAnimationItem delay="0.2s" threshold={0.3}>
            <Slider
              dots
              variableWidth
              speed={300}
              rows={1}
              arrows={false}
              infinite={false}
              dotsClass={`slick-dots ${styles['carousel-dots']}`}
              className={styles['slider']}
              slidesToShow={3}
              responsive={[
                {
                  breakpoint: defaultCardWidth * 3.4,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: defaultCardWidth * 3,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
              useTransform={false}
              ref={carouselRef}
            >
              {assets?.map((asset) => (
                <LandingNftCard key={asset.id} className={styles['card']} asset={asset} />
              ))}
            </Slider>
          </ScrollInAnimationItem>
        </div>
      </div>
    </section>
  );
};

export default LandingNftsSection;
