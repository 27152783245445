import { twMerge } from 'tailwind-merge';

import { DEFAULTS } from 'constants/defaults.constant';

import { ReactComponent as BitgetIcon } from 'assets/landing/exchange-services/Bitget white.svg';
import { ReactComponent as BitmartIcon } from 'assets/landing/exchange-services/Bitmart.svg';
import { ReactComponent as KucoinIcon } from 'assets/landing/exchange-services/kucoin.svg';
import { ReactComponent as LBankIcon } from 'assets/landing/exchange-services/LBANK.svg';
import { ReactComponent as MexcGlobal } from 'assets/landing/exchange-services/mexc-global.svg';
import { ReactComponent as NFTTrade } from 'assets/landing/exchange-services/nft-trade.svg';
import { ReactComponent as OnePlanet } from 'assets/landing/exchange-services/one-planet.svg';
import { ReactComponent as OpenSea } from 'assets/landing/exchange-services/open-sea.svg';
import { ReactComponent as Poloniex } from 'assets/landing/exchange-services/poloniex.svg';
import { ReactComponent as Rarible } from 'assets/landing/exchange-services/rarible.svg';
import { ReactComponent as Uniswap } from 'assets/landing/exchange-services/uniswap.svg';

import Button from 'components/buttons/Button';
import FreeRMVControl from 'components/freeRMVControl/FreeRMVControl';
import ScrollInAnimationItem from 'components/scrollInAnimationItem/ScrollInAnimationItem';
import VideoBanner from 'components/videoBanner/VideoBanner';

import { SourceScreenName, useFundsEvents } from 'hooks/events/useFundsEvents';
import { useEnv } from 'hooks/useEnv';
import { useHomePageNavigate } from 'hooks/useHomePageNavigate';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
import { useWindowSize } from 'hooks/useWindowSize';

import FullWidthVideo from 'assets/landing/banner/full-width-landing-banner.mp4';
import MediumVideo from 'assets/landing/banner/medium-landing-banner.mp4';

import styles from './LandingBanner.module.scss';

const LandingBanner = ({ onInitialized }: { onInitialized: () => void }) => {
  const MEDIUM_DEVICE_SCREEN_SIZE = 768;
  const { kucoinExchangeCurrency } = useEnv();
  const [windowInnerWidth] = useWindowSize();
  const scrollRef = useHorizontalScroll();
  const isSmallDevice = () => windowInnerWidth < MEDIUM_DEVICE_SCREEN_SIZE;
  const { navigateToHomePage } = useHomePageNavigate();

  const { sendFundsBuyClickedEvent } = useFundsEvents();

  const handleClick = () => {
    window.open(DEFAULTS.CEXLink(kucoinExchangeCurrency), '_blank');
    sendFundsBuyClickedEvent({ sourceScreen: SourceScreenName.LandingBanner });
  };

  const videoBanner = isSmallDevice() ? MediumVideo : FullWidthVideo;

  return (
    <div className={styles['banner']}>
      <VideoBanner
        className={styles['banner-video']}
        mp4VideoUrl={videoBanner}
        videoAnimationThreshold={0}
        onInitialized={onInitialized}
      ></VideoBanner>
      <div className={styles['content']}>
        <div className={`landing-page-container ${styles['main-content']}`}>
          <ScrollInAnimationItem delay="0.1s" threshold={0.3}>
            <p className={styles['hint']}>Hold Real-World Assets</p>
          </ScrollInAnimationItem>
          <ScrollInAnimationItem delay="0.3s" threshold={0.3}>
            <h1 className={styles['caption']}>Earn from Games</h1>
          </ScrollInAnimationItem>
          <ScrollInAnimationItem delay="0.4s" threshold={0.1}>
            <p className={styles['main-text']}>Profit Sharing & Power of AI</p>
          </ScrollInAnimationItem>
          <ScrollInAnimationItem delay="0.5s" threshold={0.1}>
            <div className="tw-flex tw-flex-col tw-gap-5">
              <div className={styles['buttons']}>
                <Button className={styles['banner-button']} color="primary" onClick={navigateToHomePage}>
                  Launch app
                </Button>
                <Button className={styles['banner-button']} color="default" onClick={handleClick}>
                  Buy RMV
                </Button>
              </div>
              <FreeRMVControl className={twMerge(styles['banner-button'], 'tw-mx-auto md:tw-hidden')} size="regular" />
            </div>
          </ScrollInAnimationItem>
        </div>

        <ScrollInAnimationItem delay="0.6s" threshold={0.1} className={styles['exchange-services-wrapper']}>
          <div className={styles['exchange-services']}>
            <p className={styles['exchange-services__caption']}>we are on top cex and dex exchanges</p>
            <div ref={scrollRef} className={styles['exchange-services__content']}>
              <a
                href="https://www.kucoin.com/trade/RMV-USDT"
                target="_blank"
                className="scale-in-hover"
                aria-label="Kucoin"
                rel="noreferrer"
              >
                <KucoinIcon />
              </a>
              <a
                href="https://www.bitget.com/spot/RMVUSDT"
                target="_blank"
                className="scale-in-hover"
                aria-label="Bitget"
                rel="noreferrer"
              >
                <BitgetIcon />
              </a>
              <a
                href="https://www.lbank.com/trade/rmv_usdt"
                target="_blank"
                className="scale-in-hover"
                aria-label="LBank"
                rel="noreferrer"
              >
                <LBankIcon />
              </a>
              <a
                href="https://www.bitmart.com/trade/en-US?symbol=RMV_USDT"
                target="_blank"
                className="scale-in-hover"
                aria-label="Bitmart"
                rel="noreferrer"
              >
                <BitmartIcon />
              </a>
              <a
                href="https://www.mexc.com/"
                target="_blank"
                className="scale-in-hover"
                aria-label="Mexc Global"
                rel="noreferrer"
              >
                <MexcGlobal />
              </a>
              <a
                href="https://poloniex.com/"
                target="_blank"
                className="scale-in-hover"
                aria-label="Poloniex"
                rel="noreferrer"
              >
                <Poloniex />
              </a>
              <a
                href="https://uniswap.org/"
                target="_blank"
                className="scale-in-hover"
                aria-label="Uniswap"
                rel="noreferrer"
              >
                <Uniswap />
              </a>
            </div>
          </div>
        </ScrollInAnimationItem>
        <ScrollInAnimationItem delay="0.6s" threshold={0.1} className={styles['collections-services-wrapper']}>
          <div className={styles['collections-services']}>
            <p className={styles['collections-services__caption']}>buy our Digital Assets collections on</p>
            <div ref={scrollRef} className={styles['collections-services__content']}>
              <a
                href="https://opensea.io/collection/reality-metaverse-genesis-collection"
                target="_blank"
                className="scale-in-hover"
                aria-label="OpenSea"
                rel="noreferrer"
              >
                <OpenSea />
              </a>
              <a
                href="https://rarible.com/"
                target="_blank"
                className="scale-in-hover"
                aria-label="Rarible"
                rel="noreferrer"
              >
                <Rarible />
              </a>
              <a
                href="https://nftrade.com/"
                target="_blank"
                className="scale-in-hover"
                aria-label="NFTTrade"
                rel="noreferrer"
              >
                <NFTTrade />
              </a>
              <a
                href="https://www.oneplanetnft.io/"
                target="_blank"
                className="scale-in-hover"
                aria-label="OnePlanet"
                rel="noreferrer"
              >
                <OnePlanet />
              </a>
            </div>
          </div>
        </ScrollInAnimationItem>
      </div>
    </div>
  );
};

export default LandingBanner;
