import { useContext } from 'react';
import Slider from 'react-slick';

import { ScrollAnimationType } from '../../../enums/scroll-animation-type.enum';

import { LANDING_PARTNERS } from '../constants/landing-partners.constants';

import { ScrollContext } from '../../../contexts/ScrollContext';

import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import { useLandingScrollSectionState } from '../../../hooks/landing/useLandingScrollSectionState';

import styles from './LandingPartnersSection.module.scss';

const LandingPartnersSection = () => {
  useLandingScrollSectionState('partners');

  const classNames = ['landing-page-container', styles['section']].join(' ');
  const itemsPerSlide = 4;
  const partnersSmall = Array(Math.ceil(LANDING_PARTNERS.length / itemsPerSlide))
    .fill(() => null)
    .map((value, index) =>
      [
        LANDING_PARTNERS[index * itemsPerSlide],
        LANDING_PARTNERS[index * itemsPerSlide + 1],
        LANDING_PARTNERS[index * itemsPerSlide + 2],
        LANDING_PARTNERS[index * itemsPerSlide + 3],
      ].filter((partner) => partner !== undefined)
    );

  const { sectionRefs } = useContext(ScrollContext);

  return (
    <section className={styles['section']} ref={sectionRefs.partners}>
      <div className={classNames}>
        <div className={styles['content']}>
          <ScrollInAnimationItem animationType={ScrollAnimationType.slideInTop} delay="0.1s" threshold={0.3}>
            <h2 className={styles['title']}>Partners & Backers</h2>
          </ScrollInAnimationItem>
          <ScrollInAnimationItem
            className={styles['arrow']}
            delay="0.4s"
            threshold={0.3}
            animationType={ScrollAnimationType.slideInLeft}
          >
            <p className={`landing-main-text ${styles['subtext']}`}>
              We are proud to collaborate with industry-leading companies that share our vision and actively contribute
              to the expansion of the Reality Metaverse ecosystem. Our partners bring expertise, innovation, and
              strategic value, helping us build a sustainable and rewarding digital economy. From gaming studios to
              blockchain pioneers, each partnership strengthens our mission to connect the real and digital worlds.
            </p>
          </ScrollInAnimationItem>

          <div className={`${styles['partners-wrapper']} ${styles['partners-list']}`}>
            {LANDING_PARTNERS.map(({ ImageComponent, label, link }, index) => (
              <ScrollInAnimationItem
                key={label}
                className={styles['partners-item-container']}
                delay={`${0.2 + index * 0.1}s`}
                threshold={0.3}
              >
                <a aria-label={label} className={styles['partners-item']} href={link} rel="noreferrer" target="_blank">
                  <ImageComponent />
                </a>
              </ScrollInAnimationItem>
            ))}
          </div>

          <Slider
            autoplay
            dots
            infinite
            arrows={false}
            lazyLoad="ondemand"
            dotsClass={`slick-dots ${styles['partners-slider__dots']}`}
            className={styles['partners-slider']}
            speed={1000}
            slidesToScroll={1}
            slidesToShow={1}
          >
            {partnersSmall.map((partnersGroup) => (
              <div key={`group-parent-${partnersGroup[0].label}`}>
                {partnersGroup.map(({ ImageComponent, label, link }, index) => (
                  <a
                    key={`group-link-${label}`}
                    aria-label={label}
                    className={styles['partners-item']}
                    href={link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <ImageComponent />
                  </a>
                ))}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default LandingPartnersSection;
