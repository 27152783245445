import { useContext, useEffect, useState } from 'react';

import { ScrollContext } from 'contexts/ScrollContext';

import Page from 'components/page/Page';
import PageWrapper from 'components/pageWrapper/PageWrapper';

import styles from './Landing.module.scss';
import LandingBanner from './landingBanner/LandingBanner';
import LandingBusinessModelSection from './landingBusinessModelSection/LandingBusinessModelSection';
import LandingFaq from './landingFaq/LandingFaq';
import LandingGamesSection from './landingGamesSection/LandingGamesSection';
import LandingMainSection from './landingMainSection/LandingMainSection';
import LandingNftsSection from './landingNftsSection/LandingNftsSection';
import LandingOurWhitepaperSection from './landingOurWhitepaperSection/LandingOurWhitepaperSection';
import LandingPartnersSection from './landingPartnersSection/LandingPartnersSection';
import LandingPlayersSection from './landingPlayersSection/LandingPlayersSection';
import LandingRoadmapSection from './landingRoadmapSection/LandingRoadmapSection';
import LandingStatsSection from './landingStatsSection/LandingStatsSection';
import LandingTokenDistributionSection from './landingTokenDistrubutionSection/LandingTokenDistributionSection';
import LandingVideosSection from './landingVideosSection/LandingVideosSection';

const Landing = () => {
  // TODO use this property for images lazy loading, after video is loaded
  const [isBannerInitialized, setIsBannerInitialized] = useState<boolean>(false);
  const { resetActiveScrollSection } = useContext(ScrollContext);

  useEffect(() => {
    return () => {
      resetActiveScrollSection();
    };
  }, []);

  return (
    <Page hideLoader withScrollbar className={styles['landing-page']}>
      <PageWrapper homeFooter={false} landingFooter>
        <div className={styles['content']}>
          <LandingBanner onInitialized={() => setIsBannerInitialized(true)} />
          <div className={styles['sections']}>
            <LandingMainSection />
            <div className={'landing-page-container'}>
              <p className={`landing-main-text text-center ${styles['main-text']}`} style={{ textAlign: 'center' }}>
                A thriving community of 8.5 milion players engaging with real-world assets across multiple games.
              </p>
            </div>
            <LandingNftsSection />
            <LandingGamesSection />
            <LandingPlayersSection mainAnimationLoaded={isBannerInitialized} />
            <LandingBusinessModelSection mainAnimationLoaded={isBannerInitialized} />
            <LandingStatsSection />
            <div className="landing-page-dark-wrapper">
              <LandingRoadmapSection />
              <LandingVideosSection />
            </div>
            <LandingTokenDistributionSection />
            <LandingOurWhitepaperSection />
            <LandingFaq />
            <LandingPartnersSection />
            {/* TODO! add when newsletter subscription is implemented in the API */}
            {/* <LandingNewsletterSubscriptionSection mainAnimationLoaded={isBannerInitialized} /> */}
          </div>
        </div>
      </PageWrapper>
    </Page>
  );
};

export default Landing;
