import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { IVenue } from 'models/i-venue.interface';
import { IState } from 'models/reducers/i-state.interface';

import { ROUTES } from 'constants/routes.constant';

import { addInfoMessageAction } from 'actions/global-actions';

import { SourceScreenName } from 'hooks/events/useFundsEvents';
import { useEnv } from 'hooks/useEnv';
import { useHomePageNavigate } from 'hooks/useHomePageNavigate';

import RealityLogo from 'assets/logos/rda_logo.png';
import { ReactComponent as Deals } from 'assets/menu/icons/deals.svg';
import { ReactComponent as Leaders } from 'assets/menu/icons/leaders.svg';
import { ReactComponent as Lootbox } from 'assets/menu/icons/lootbox.svg';
import { ReactComponent as Market } from 'assets/menu/icons/market.svg';
import { ReactComponent as News } from 'assets/menu/icons/news.svg';
import { ReactComponent as Spotlight } from 'assets/menu/icons/spotlight.svg';
import { ReactComponent as World } from 'assets/menu/icons/world.svg';

import BuyRMVControl from '../../buyRMVControl/BuyRMVControl';
import FreeRMVControl from '../../freeRMVControl/FreeRMVControl';
import MenuElement from '../menuElement/MenuElement';
import MenuLandingLink from '../menuLandingLink/MenuLandingLink';
import MenuToggle from '../menuToggle/MenuToggle';
import MenuWalletButton from '../menuWalletButton/MenuWalletButton';
import styles from './MobileHeader.module.scss';
import SocialMediaAndLinks from './socialMediaAndLinks/SocialMediaAndLinks';

interface IMobileHeader {
  className?: string;
}

const MobileHeader = ({ className }: IMobileHeader) => {
  const { lootboxOpen } = useEnv();

  const dispatch = useDispatch();
  const location = useLocation();
  const { homePage, mapPageDisabled } = useHomePageNavigate();
  const [menuVisible, setMenuVisible] = useState(false);
  const activeVenue = useSelector((state: IState) => state.venuesActive.activeVenue) as IVenue;
  const searchData = useSelector((state: IState) => state.search.search);

  useEffect(() => {
    setMenuVisible(false);
  }, [location]);

  useEffect(() => {
    document.addEventListener('keydown', onEscape, false);

    return () => {
      document.removeEventListener('keydown', onEscape, false);
    };
  }, []);

  const onEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setMenuVisible(false);
    }
  };

  const toggleMenuVisibility = () => {
    setMenuVisible(!menuVisible);
  };

  const createURL = (): string => {
    if (mapPageDisabled) {
      return homePage;
    }

    let url = ROUTES.PORTAL;

    if (activeVenue) {
      url += `/${activeVenue?.type}/${activeVenue?.id}`;
    }

    if (searchData) {
      url += `?s=${searchData}`;
    }

    return url;
  };

  const showDisabledMapMessage = (): void => {
    if (!mapPageDisabled) {
      return;
    }

    dispatch(addInfoMessageAction('Open website on desktop to enable map functionality'));
  };

  return (
    <div className={`${className || ''} ${styles['menu']}`}>
      <nav className={`${styles['nav']} ${menuVisible ? styles['nav--expanded'] : ''}`}>
        <Scrollbars style={{ width: 'calc(100% + 16px)', height: '100%' }}>
          <div className="tw-mr-2.5 tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-between">
            <div className="tw-w-full">
              <div className="tw-pb-7.5 tw-pt-2.5">
                <MenuLandingLink />
              </div>
              <ul className="list-reset tw-flex tw-flex-col tw-gap-y-8">
                <li className="tw-text-base" onClick={() => showDisabledMapMessage()}>
                  <div className={twMerge(mapPageDisabled && 'tw-pointer-events-none tw-opacity-40')}>
                    <MenuElement
                      active={location.pathname.includes(ROUTES.PORTAL)}
                      title="MAP"
                      link={createURL()}
                      icon={<World />}
                    />
                  </div>
                </li>
                <li className="tw-text-base">
                  <MenuElement
                    active={location.pathname === ROUTES.MARKET}
                    title="MARKETPLACE"
                    link={ROUTES.MARKET}
                    icon={<Market />}
                  />
                </li>
                <li>
                  <MenuElement
                    active={location.pathname === ROUTES.DEALS}
                    title="Deals"
                    link={ROUTES.DEALS}
                    icon={<Deals />}
                  />
                </li>
                <li className="tw-text-base">
                  <MenuElement
                    active={location.pathname === ROUTES.SPOTLIGHT}
                    title="Spotlight"
                    link={ROUTES.SPOTLIGHT}
                    icon={<Spotlight />}
                  />
                </li>
                {lootboxOpen && (
                  <li>
                    <MenuElement
                      active={location.pathname === ROUTES.LOOTBOX}
                      title="Loot boxes"
                      link={ROUTES.LOOTBOX}
                      icon={<Lootbox />}
                    />
                  </li>
                )}
                <li>
                  <MenuElement
                    active={location.pathname === ROUTES.LEADERS}
                    title="Leaders"
                    link={ROUTES.LEADERS}
                    icon={<Leaders />}
                  />
                </li>
                <li>
                  <MenuElement
                    active={location.pathname === ROUTES.PROMOTIONS}
                    title="News"
                    link={ROUTES.PROMOTIONS}
                    icon={<News />}
                  />
                </li>
              </ul>
              <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-pb-18 tw-pt-7.5">
                <BuyRMVControl sourceScreen={SourceScreenName.MobileHeader} />
                <FreeRMVControl />
              </div>
            </div>
            <SocialMediaAndLinks />
          </div>
        </Scrollbars>
      </nav>
      {menuVisible && <div className={styles['nav__backdrop']} onClick={() => setMenuVisible(false)}></div>}
      <button
        className={`button--styleless ${styles['menu-toggle']} ${menuVisible ?? styles['menu-toggle--collapsed']}`}
        onClick={toggleMenuVisibility}
        aria-label="Open navigation menu"
      >
        <MenuToggle isActive={menuVisible}></MenuToggle>
      </button>
      <Link to={'/'} aria-label="Home page" className="button--styleless">
        <img
          className={styles['menu-header__avatar']}
          src={RealityLogo}
          alt="Reality Metaverse"
          style={{ height: '1.2em' }}
        />
      </Link>
      <MenuWalletButton />
    </div>
  );
};

export default MobileHeader;
